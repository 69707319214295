import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import UserData from './pages/userdata/UserData';
import Sucess from './pages/sucessLogin/Sucess';
const App = () => {

  const NotFound = () => {
     <div>
        <h1>Not Found</h1>
      </div>
  }


  return (
    <Router>
      <div>
      
        {/* Configuración de rutas */}
        <Routes>
          <Route path="/inicio" element={<LoginPage />} />
          <Route path="/userdata" element={<UserData />} />
          <Route path="/sucess" element={<Sucess />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;
