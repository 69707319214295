import React from 'react'

import './Sucess.css';


const Sucess = () => { 
  return (
    <div className='User-container'>
      <h1 className='header'>Astro IPTV </h1>
      <p >Sesion iniciada, Revisa tu TV y disfruta de nuestra aplicacion.</p>
    </div>
  );
};

export default Sucess;
