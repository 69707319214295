import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './LoginPage.css';
import axios from 'axios';


const LoginPage = () => {

  const [otp, setOtp] = useState(['', '', '', '', '', '', '', '']);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  useEffect(() => {
    console.log(code);
    if (code) {
      setOtp(code.split(''));
      verifyOtp(code);
    }
  }, [code]);

    const handleChange = (e, index) => {
      let value = e.target.value;
  
      if (/[^0-9]/.test(value)) {
        return;
      }
      otp[index] = value;
  
      if (index < otp.length - 1 && value !== '') {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
      setOtp([...otp]);
    };


    const verifyOtp = async (otpValue) => {
      //make otpValue to int 
      otpValue = parseInt(otpValue);
      await axios
        .post('http://74.208.89.50/api/otp/verifyOTP', { otp: otpValue })
        .then((response) => {
         
          //if response is 200 then redirect to userdata page
          if(response.status === 200){
            window.location.href = '/userdata?userId='+response.data;
          }else{
            //if response is not 200 then show error message
            alert(response.data);
          }

         
          
        })
        .catch((err) => alert("Codigo Incorrecto o Expirado"));
    };
  

  return (
    <div className='login-container'>
      <h1>Astro IPTV </h1>
      <p>Ingresa el PIN de 8 dígitos generado en la aplicacion.</p>
      <div className='otp-input-box'>
        {otp.map((d, i) => (
          <input
            id={`otp-input-${i}`}
            key={i}
            type='text'
            value={d}
            onChange={(e) => handleChange(e, i)}
            maxLength='1'
          />
        ))}
      </div>
      <button 
      onClick={() => verifyOtp(otp.join(''))}
      class="dlCygn btnprimary" type="submit">CONTINUAR</button>
    </div>
  );
};

export default LoginPage;
